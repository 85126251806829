import React from "react"
import ImageRenderer from "./ImageRenderer"
import BrandParagraph from "./BrandParagraph"

const IconBox = ({
  componentEC,
  image,
  imageComponentEC,
  imageEC,
  title,
  titleEC,
  description,
  descriptionEC,
  descriptionLength,
  btn,
  arrowImage,
  arrowImageEC,
}) => {
  return (
    <div className={`relative ${componentEC}`}>
      <div
        className={`bg-[#f0facc] w-[110px] h-[110px] flex items-center justify-center shadow rounded-[500%] ${imageComponentEC}`}
      >
        <div className={imageEC}>
          <ImageRenderer img={image.path} alt={image.alt} />
        </div>
      </div>
      <div className="md:pt-5">
        {title && (
          <h4
            className={`text-[18px] leading-[1.4em] font-[600] text-[#000000] mb-3 ${titleEC}`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <BrandParagraph text={description} textEC={descriptionEC} />
        {/* {btn && (
          <Link
            to="#"
            href="#"
            className="text-secondary font-[600] block mt-3 hover:underline"
          >
            {btn.text}
          </Link>
        )} */}
      </div>
      {arrowImage && (
        <div className={`absolute left-[0] top-[0] ${arrowImageEC}`}>
          <ImageRenderer img={arrowImage.path} alt={arrowImage.alt} />
        </div>
      )}
    </div>
  )
}

export default IconBox
